<template>
    <div class="footer_link">
        <div class="link_box">
            <div class="link_main_box">
                <div class="link_wrap" v-for="({categoryName,categoryId,articleList},index) in navList.data" :key="index">
                    <p @click="toarticle(categoryId)">{{categoryName}}</p>
                    <li v-for="({title,cateId,articleId},index) in articleList" :key="index">
                        <router-link :to="{ path: '/article', query: { cateId: cateId,articleId:articleId }}" replace>
                            <a>{{title}}</a>
                        </router-link>
                    </li>
                </div>
                <div class="link_wrap" v-if="navList.data.length > 0">
                    <div class="bottom_code_box flex_row_center_center">
                        <div v-if="configInfo.foot_qr_code1" class="bottom_code flex_row_center_center">
                            <img :src="configInfo.foot_qr_code1" />
                        </div>
                        <div v-if="configInfo.foot_qr_code2" class="bottom_code flex_row_center_center">
                            <img :src="configInfo.foot_qr_code2"/>
                        </div>
                    </div>
                </div>
                <div class="bottom_code_box flex_row_center_center" v-else>
                    <div v-if="configInfo.foot_qr_code1" class="bottom_code flex_row_center_center">
                        <img :src="configInfo.foot_qr_code1" />
                    </div>
                    <div v-if="configInfo.foot_qr_code2" class="bottom_code flex_row_center_center">
                        <img :src="configInfo.foot_qr_code2"/>
                    </div>
                </div>
            </div>
            <div class="bottom_message">
                <img class="bottom_phone_img" v-if="configInfo.basic_site_phone" src="@/assets/footer/phone.png" />
                <span class="bottom_phone" v-if="configInfo.basic_site_phone">{{configInfo.basic_site_phone}}</span>
                <span class="bottom_day">
                    工作时间：周一 ～ 周日
                    <span>9：30</span>
                    <span class="bottom_day_line">~</span>
                    17：00
                </span>
            </div>
        </div>
        <div class="bottom_box">
            <FooterBottom></FooterBottom>
        </div>
    </div>
    <AuthScreen @closeAuth="closeAuth" v-if="!show_open_screen && show_aurth_screen"></AuthScreen>
</template>

<script>
    import FooterBottom from "./FooterBottom";
    import { reactive, getCurrentInstance, ref, watch, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import AuthScreen from "@/components/authScreen";

    export default {
        name: "FooterLink",
        components: {
            FooterBottom,
            AuthScreen
        },
        props: ["caseNumber"],
        setup() {
            const bg = {
                backgroundImage:
                    "url(" + require("../assets/footer/footer_bg.png") + ")",
                backgroundSize: "100% 100%"
            };
            const store = useStore();
            const configInfo = ref(store.state.configInfo);
            const navList = reactive({ data: [] });
            const getInitData = () => {
                const { proxy } = getCurrentInstance();
                let param = {};
                param.cateSize = 6;
                param.articleSize = 5;
                proxy.$get('v3/cms/front/article/helpList', param).then(res => {
                    if (res.state == 200) {
                        navList.data = res.data;
                    }
                })
            }
            getInitData();
            
            const show_open_screen = ref(true);
            const show_aurth_screen = ref(false);

            const getAuthInfo = () => {
                // 个人用户判断展示企业认证弹窗
                if(store.state.memberInfo && store.state.memberInfo.memberType == 1){
                    let state = localStorage.getItem('AuthenState')
                    let time = new Date();
                    let now = time.getTime();
                    if(!state){
                        localStorage.setItem('AuthenState',now);
                        show_aurth_screen.value = true;
                    }else if(now - state >= 86400000){
                        localStorage.setItem('AuthenState',now);
                        show_aurth_screen.value = true;
                    }
                }
            };
            getAuthInfo();

            onMounted(()=>{
                if(!(store.state.openScreenInfo && store.state.openScreenInfo.isShow)){
                    show_open_screen.value = false;
                }
            })

            watch(() => store.state.openScreenInfo.isShow, (ov, nv) => {
                show_open_screen.value = false;
            })

            watch(() => store.state.configInfo, (ov, nv) => {
                configInfo.value = ov;
            })

            const closeAuth =()=> {
                show_aurth_screen.value = false;
            };

            return { bg, navList, configInfo, show_open_screen, show_aurth_screen, closeAuth }
        },
    };
</script>

<style lang="scss" scoped>
    .footer_link {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        color: $colorI;
        background-color: $colorF;

        .link_box {
            position: relative;
            width: 100%;
            height: 295px;

            background-image: url('../assets/footer/footer_link_bg.png');
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;

            .link_main_box {
                position: relative;
                width: 1210px;
                height: 252px;
                // border-top: 1px dashed #f2f2f2;
                margin: 0 auto;
                padding-top: 40px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .bottom_code_box {
                    position: absolute;
                    right: 0;
                    top: 40px;

                    .bottom_code {
                        width: 110px;
                        height: 110px;
                        background: #ffffff;
                        margin-left: 10px;

                        img {
                            width: 96px;
                            height: 96px;
                        }
                    }
                }
            }

            .link_wrap {

                .bottom_code_box {
                    position: relative;
                    right: 0;
                    top: 0;
                }

                text-align: center;

                p {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    // color: #333333;
                }

                li {
                    line-height: 25px;

                    a {
                        color: $colorH;
                        // color: #333333;

                        &:hover {
                            color: $colorJ;
                        }
                    }
                }
            }

            .link_wrap:nth-child(1) {
                /*width: 295px;*/
                /*text-align: left;*/
            }

            .link_wrap:nth-child(2) {
                /*width: 270px;*/
            }

            .link_wrap:nth-child(3) {
                /*width: 280px;*/
            }

            .link_wrap:nth-child(4) {
                /*width: 280px;*/
            }

            .link_wrap:nth-child(5) {
                /*width: 280px;*/
            }
        }

        .bottom_message {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            font-size: $fontD;
            font-weight: 700;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            cursor: default;

            .bottom_phone_img {
                width: 23px;
                height: 23px;
                margin-right: 16px;
                margin-top: 4px;
            }

            .bottom_phone {
                display: block;
                line-height: 26px;
                color: #ffffff;
                font-size: 20px;
                font-family: SourceHanSansCN-Medium;
                margin-top: 7px;
                margin-left: 0;
                margin-right: 16px;
                font-weight: 400;
            }

            .iconfont {
                margin-right: 12px;
                font-size: $fontB;
            }

            .bottom_day {
                display: block;
                height: 21px;
                line-height: 22px;
                color: #ffffff;
                font-size: 18px;
                font-family: SourceHanSansCN-Medium;
                margin-top: 7px;
                margin-left: 10px;
                font-weight: 400;

                span {
                    margin-left: 12px;
                }

                .bottom_day_line {
                    position: relative;
                    bottom: 2px;
                    font-size: 14px;
                    margin-left: 6px;
                    margin-right: 0px;
                }
            }
        }

        .bottom_box {
            border-top: 1px solid $colorH;
            position: relative;
            width: 1903px;
            overflow: hidden;
            height: 150px;
        }
    }
</style>
