<!--
 * @Author: your name
 * @Date: 2021-01-09 20:19:11
 * @LastEditTime: 2021-01-13 17:21:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/components/FooterBottom.vue
-->
<template>
  <div class="footer_bottom">
    <div class="partner">
      <p>合作伙伴：</p>
      <template v-for="item in navlist.data" :key="item.linkId">
        <a :href="item.linkUrl">{{item.linkName}}</a>
      </template>
    </div>
    <!-- <ul class="footer_list">
      <li v-for="({linkName,linkUrl},index) in navlist.data" :key="index">
        <a :href="linkUrl" target="_blank">
          {{linkName}}
        </a>
      </li>
    </ul> -->
    <p class="copy_right">{{configInfo.basic_site_copyright}} 版权所有 <a style="color:#999" href="https://beian.miit.gov.cn/" target="_blank">{{configInfo.basic_site_icp}}</a></p>
    <p class="author" v-if="configInfo.basic_site_technical_support">
      {{configInfo.basic_site_technical_support}} 提供技术支持
    </p>
  </div>
</template>

<script>
  import { reactive, getCurrentInstance, ref } from 'vue'
  import { useStore } from 'vuex';
  export default {
    name: "FooterBottom",
    setup() {
      const navlist = reactive({ data: [] });
      const store = useStore();
      const configInfo = ref(store.state.configInfo)
      const getInitData = () => {
        const { proxy } = getCurrentInstance();
        proxy.$get('v3/cms/front/friendLink/list').then(res => {
          if (res.state == 200) {
            navlist.data = res.data;
          }
        })
      }
      getInitData();
      return { navlist, configInfo };
    },
  };
</script>

<style lang="scss" scoped>
  .footer_bottom {
    position: absolute;
    left: 50%;
    // bottom: 0;
    width: 1900px;
    color: #999;
    font-size: 12px;
    background-color: #333333;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: -952px;
    padding-top: 12px;
    padding-bottom: 12px;
    overflow: hidden;

    .partner {
      margin-top: 10px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      p {
        color: $colorK;
        cursor: default;
      }
      
      a {
        position: relative;
        color: $colorH;
        padding-left: 10px;
        padding-right: 10px;

        &:after {
          content: '';
          width: 0.5px;
          height: 12px;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -6px;
          background: $colorH;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:hover {
          color: $colorK;
        }
      }
    }

    .footer_list {
      width: 1200px;
      margin: 15px auto;
      display: flex;
      margin-bottom: 10px;
      margin-top: 15px;
      flex-wrap: wrap;
      justify-content: center;

      li {
        box-sizing: border-box;
        padding: 0 7px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        color: #fff;
        line-height: 19px;
        cursor: pointer;

        a {
          color: #fff;
        }
      }
    }

    li:last-child {
      border-right: none;
    }

    .copy_right {
      letter-spacing: 1px;
      margin-bottom: 10px;
      cursor: default;
    }
  }
</style>