<!-- 企业认证首页弹窗 -->
<template>
  <div >
    <!-- <div class="sld_authen_screen_main">
      <img class="sld_authen_screen_bg" src="@/assets/auth_bg.png"/>
      <img class="sld_authen_screen_close" @click="close" src="@/assets/auth_close.png"/>
      <div class="sld_authen_screen_btn" @click="goto">立即认证</div>
    </div> -->
      <!-- 点击加购弹窗时弹窗 start -->
         <div class="mask_personal"  @click="close">
         </div>
         <div class="personal_box" >
            <div class="personal_img">
               <img src="@/assets/ze-cros.png" alt="" @click="close">
            </div>
            <div class="personal_cont">
               <p>诚邀您进行企业认证</p>
               <!-- <p>请注意，加入收藏并不代表您已选购该产品</p> -->
            </div>
            <div class="personal_btn">
               <span @click="goto">立即认证>></span>
            </div>
         </div>
      <!-- 点击收藏时弹窗 end -->
  </div>
</template>

<script>
  import { getCurrentInstance, ref } from 'vue'
  import { useRouter } from 'vue-router'
  export default {
    name: 'AuthScreen',
    setup(props, { emit }) {
      const router = useRouter();
      const { proxy } = getCurrentInstance();
      const height = ref(document.documentElement.clientHeight);
      
      const close = () => {
        emit('closeAuth')
      }
      window.scroll(0, 0);

      const goto =()=> {
        emit('closeAuth');
        router.push('/member/authen');
      }

      return { close, height, goto }
    }
  }
</script>

<style lang="scss" scoped>
 
  .mask_personal{
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}
.personal_box{
    padding-bottom: 20px;
    width: 367px;
    //   height: 310px;
    background:  rgba(16, 16, 16, 0.59);
    border-radius: 4px;
    position: fixed;
    left: 50%;
    top: 60%;
    z-index: 1000;
    transform:translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .personal_img{
        text-align: right;
        padding-top: 5px;
        padding-right: 5px;
        img{
            width: 20px;
            height: 20px;
        }
    }
    .personal_cont{
        margin-top: 30px;
        p{
            color: #fff;
            font-size: 14px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
    .personal_btn{
        margin: 0 auto;
        margin-top: 20px;
        span{
            color: #fff;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>